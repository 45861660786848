@mixin textIcon() {
  color: inherit;
  // display: inline-block;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  // padding-left: 24px;
  margin-bottom: 24px;
  position: relative;

  &:before {
    content: "";
    width: 12px;
    height: 16px;
    margin-right: 16px;
    flex: none;

    @include bp(desktop-large) {
      margin-right: 10px;
    }

    @include bp(desktop-wide) {
      margin-right: 12px;
    }
  }

  @include bp(tablet) {
    font-size: 16px;
  }
}

@mixin textIconLocation() {
  &:before {
    background: url("/images/pin-icon.svg");
    background-repeat: no-repeat;
  }
}

@mixin textIconPlay() {
  &:before {
    background: url("/images/play-icon.svg");
  }
}
