@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.container {
  width: 100%;
  padding: 0 $container-padding;
  margin-right: auto;
  margin-left: auto;
  @include bp(mobile) {
    padding: 0 calc($container-padding + 8px);
  }
  @include bp(tablet) {
    max-width: calc(
      $container-width-tablet + calc($container-padding + 8px) * 2
    );
  }
  @include bp(desktop-large) {
    padding: 0 calc($container-padding - 8px);
  }
  // copying styles from vuetify
  @media (min-width: 1264px) {
    max-width: $container-width-desktop;
  }
  @include bp(desktop-wide) {
    max-width: $container-width-wide-desktop;
  }
}
