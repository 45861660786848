@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.intro {
  background-color: #ffffff;
  position: relative;
  color: $color__darkblue-2;

  @include bp(0 desktop-large) {
    display: flex;
    flex-direction: column-reverse;
  }

  h1 {
    color: inherit;
    font-weight: 700;
    line-height: 1.2;
    font-size: 26px;

    @include bp(desktop-large) {
      font-size: 36px;
    }

    @include bp(desktop-wide) {
      font-size: 40px;
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: $color__black--lighter;
    margin-top: 0;
    margin-bottom: 24px;

    @include bp(desktop-large) {
      font-size: 16px;
    }
  }
}

.intro__breadcrumbs {
  * {
    color: $color__black--lighter;
  }
}

.intro__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 2;

  @include bp(0 desktop-large) {
    padding-bottom: 32px;
  }
}

.intro__text {
  @include bp(desktop-large) {
    width: 50%;
    padding-right: 72px;
    padding-bottom: 88px;
  }
}

.intro__date {
  font-size: 20px;
  font-weight: 600;
  color: $color__blue;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  margin-top: 16px;
  margin-bottom: 24px;

  span {
    font-weight: 500;
    font-size: 12px;
  }

  @include bp(desktop-large) {
    font-size: 28px;
  }
}

.intro__location a {
  color: inherit;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  padding-left: 24px;
  margin-bottom: 24px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    width: 12px;
    height: 16px;
    background: url("/images/pin-icon.svg");
  }
}

.intro__img {
  object-fit: cover;
  object-position: center;
  z-index: 1;

  @include bp(desktop-large) {
    height: 100%;
    top: 0;
    left: 50%;
    max-width: 50%;
    position: absolute;
    object-position: left;
  }

  &.intro__img_withmobile {
    @include bp(0 desktop-wide) {
      display: none;
    }
  }
}


.intro__langbuttons {
  @include bp(0 desktop-large) {
    display: none;
  }

  display: flex;
  padding-top: 40px;

  input {
    display: none;
  }

  .intro__lang {
    color: $color__darkblue-2;
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    padding: 0 8px;
    background-color: #ffffff;
    cursor: pointer;
  }

  input:checked+.intro__lang,
  .intro__lang_active {
    color: #ffffff;
    background-color: $color__darkblue-2;

    cursor: default;
  }
}


.intro__breadcrumbs {
  @include bp(0 desktop-large) {
    display: none;
  }
}

.intro__img.intro__img_mobile {
  display: none;
  object-position: center;

  @include bp(0 desktop-wide) {
    display: block;
  }
}

.intro--home {
  color: #fff;
  position: relative;
  overflow: hidden;

  @include bp(0 mobile) {
    padding-bottom: 35%;
  }

  @include bp(mobile tablet) {
    padding-bottom: 55%;
  }

  @include bp(tablet desktop-large) {
    padding-bottom: 45%;
  }

  @include bp(desktop-large) {
    &::before {
      content: '';
      background: linear-gradient(50.93deg, rgba(0, 29, 41, 0.9) 27.6%, rgba(3, 50, 71, 0) 70.29%);
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .intro__container {
    padding-top: 32px;

    @include bp(tablet) {
      padding-top: 85px;
    }
  }

  .intro__text {
    padding-bottom: 52px;
  }

  .intro__img:not(.intro__img_mobile) {
    left: 0;
    max-width: 100%;
    object-position: right;
    width: 100%;
  }

  .intro__img {
    &.intro__img_withmobile {

      @include bp(desktop-large) {
        display: block;
      }
    }
  }

  .intro__img.intro__img_mobile {
    position: absolute;
    bottom: 0;
    max-width: none;
    // object-position: center;
    width: 100%;
    min-height: 100%;
    display: none;

    @include bp(0 desktop-large) {
      display: block;
    }
  }

}