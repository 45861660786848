@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.breadcrumbs {
  @include bp(mobile) {
    padding-top: 42px;
    padding-bottom: 40px;
  }

  @include bp(tablet) {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  a {
    font-weight: 500;
  }

  padding-top: 28px;
  padding-bottom: 24px;
}

.breadcrumbs__item {
  font-size: 10px;
  color: inherit;

  &_home {
    opacity: 1;
  }

  &_page {
    span {
      opacity: 0.4;
    }

    @include bp(mobile tablet) {
      margin-left: 16px;
      // @include arrow($color__green--light, 10px, 10px, 2px, false);
    }

    @include bp(desktop-large) {
      margin-left: 8px;
    }

    margin-left: 8px;

    &:before {
      content: "/";
      display: inline-block;
      margin-right: 8px;
    }

    // @include arrow($color__green--light, 6px, 6px, 1px, false);
  }

  &_page:before {
    @include bp(desktop-large) {
      margin-top: 10px;
    }
  }

  // &_home:after {
  //   @include bp(mobile tablet) {
  //     width: 10px;
  //     height: 10px;
  //     margin-top: 10px;
  //     margin-left: 16px;

  //     border-width: 2px;
  //   }
  //   @include bp(desktop-large) {
  //     margin-top: 10px;
  //     margin-left: 10px;
  //   }

  //   display: inline-block;

  //   width: 6px;
  //   height: 6px;
  //   margin-top: 6px;
  //   margin-left: 8px;

  //   content: "";
  //   transform: rotate(-45deg);
  //   vertical-align: top;

  //   border-right: 1px solid $color__green--light;
  //   border-bottom: 1px solid $color__green--light;
  // }
}