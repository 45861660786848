@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.meet__form {
  margin-top: 16px;

  .meetForm__footer {
    margin: 48px 0 0 0;

    @include bp(mobile) {
      margin: 94px 0 0 0;
    }

    @include bp(tablet) {
      margin: 56px 0 0 0;
    }

    @include bp(desktop-large) {
      margin: 32px 0 0 0;
    }

    p {
      font-weight: 300;
      margin-top: 32px;

      @include bp(mobile tablet) {
        font-size: 24px;
      }
    }

    p a {
      font-weight: 400;
    }
  }
}

.signup__perfields {
  margin-top: 24px;
  align-items: center;
}

.signup__with_btn {
  font-size: 16px;
  font-weight: 500;
  // line-height: 48px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
}

.meetForm__btn {
  width: 100%;

  @include bp(450px) {
    width: auto;
  }

  &+& {
    margin-top: 20px;

    @include bp(450px) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.meet__success {
  .meetSuccess__title {
    font-weight: 700;
    font-size: 44px;
    line-height: 58px;
    margin: 0 0 20px 0;
  }

  .meetSuccess__text {
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
  }

  .meetSuccess__button {
    background: none;
    border: none;
    padding: 0;
    margin: 20px 0 0 0;
    font-size: 24px;
    line-height: 32px;
    color: #06e685;
    text-decoration: underline;
  }
}

.meet__error {
  color: #fff;
  background: red;
  padding: 10px;
  width: 100%;
  font-size: 20px;
  margin: 20px 0;
  border-radius: 4px;
}

.meetForm__input {
  padding-bottom: 0;
}

.meetForm__btn {
  @include bp(desktop-large) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include bp(desktop-wide) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.meetForm__btn_visit {
  margin-bottom: 10px;
  margin-right: 10px;
}

.signup__header {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;

  display: flex;
  flex-direction: column;

  h2 {
    font-size: 28px;
    line-height: 1.25;
    font-weight: 500;

    display: block;

    margin: 0 0 24px 0;

    color: inherit;

    @include bp(mobile) {
      font-size: 56px;
      line-height: 64px;

      margin: 0 0 44px 0;
    }

    @include bp(tablet) {
      font-size: 32px;
      line-height: 1;
      margin: 0 0 24px 0;
    }

    @include bp(desktop-large) {
      font-size: 36px;
      line-height: 48px;
    }

    @include bp(desktop-wide) {
      font-size: 44px;
      line-height: 53px;
    }
  }

  .signup__note {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 32px;
  }

  span {
    font-size: 22px;
    font-weight: 700;
    line-height: 1;

    position: relative;
    z-index: 0;

    display: inline-block;

    margin: 0 20px 0 0;
    padding: 0;

    text-transform: uppercase;

    &:before {
      position: absolute;
      z-index: -1;
      top: -5px;
      right: -8px;
      bottom: -5px;
      left: -8px;

      content: "";

      background: #06e685;
    }
  }

  strong {
    color: #25b778;
  }
}

label.meetForm__radio {
  display: flex;
  margin-top: 32px;

  span {
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff;
    position: relative;
    margin-right: 16px;
  }

  input {
    display: none;
  }

  input:checked~span:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    flex: 1;
    margin: 0;
    letter-spacing: 3%;
  }
}

.meetForm__textafter {
  font-size: 20px;
  margin-top: 32px;

  a {
    color: #ffffff;
    font-weight: 700;
  }
}

.signup__checkbox {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 48px;
  cursor: pointer;
}

// .signup__checkbox_text {
//   flex-shrink: 0;
// }

.signup__checkbox_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: #ccc;
  border: 2px solid #fff;
  transition: .4s;
  border-radius: 8px;
  width: 32px;
  height: 16px;
  margin: auto 0;

  &:before {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    left: 4px;
    bottom: 3.5px;
    right: auto;
    background-color: white;
    transition: .4s;
    transform: rotate(45deg);
  }
}

.signup__checkbox_field {
  opacity: 0;
  width: 0;
  height: 0;

  &:checked+.signup__checkbox_slider {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:focus+.signup__checkbox_slider {
    box-shadow: 0 0 1px rgba(0, 0, 255, 0.5);
  }

  &:checked+.signup__checkbox_slider:before {
    // transform: translateX(26px);
    left: auto;
    right: 4px;
  }
}