@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.intro {
  background-color: #ffffff;

  h1 {
    color: #ffffff;
    font-weight: 700;
    line-height: 1.2;
    font-size: 26px;

    @include bp(tablet) {
      font-size: 32px;
    }

    @include bp(desktop-large) {
      font-size: 36px;
      margin-bottom: 16px;
      max-width: 680px;
    }

    @include bp(desktop-wide) {
      font-size: 44px;
      margin-bottom: 24px;
      max-width: 752px;
    }
  }
}

.intro__breadcrumbs {
  padding-top: 16px;
  padding-bottom: 16px;

  * {
    color: $color__black--lighter;
  }

  @include bp(tablet) {
    padding-top: 30px;
  }

  @include bp(desktop-wide) {
    padding-bottom: 48px;
  }
}

.intro__image {
  position: relative;
  padding: 24px 12px 32px;
  display: block;
  cursor: pointer;
  min-height: 368px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;

  * {
    z-index: 2;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 29, 41, 0.9);
    z-index: 1;

    @include bp(tablet) {
      background: linear-gradient(
        65.67deg,
        rgba(0, 29, 41, 0.9) 34.43%,
        rgba(3, 50, 71, 0) 85.21%
      );
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }

  @include bp(tablet) {
    padding: 40px 40px 64px;
    min-height: 384px;
  }

  @include bp(desktop-large) {
    padding: 40px 48px 48px;
  }

  @include bp(desktop-wide) {
    padding: 48px 48px 56px;
    min-height: 440px;
  }
}

.intro__date {
  font-size: 20px;
  font-weight: 600;
  color: $color__blue;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  margin-bottom: 16px;

  span {
    font-weight: 500;
    font-size: 12px;
  }

  @include bp(tablet) {
    font-size: 24px;
  }

  @include bp(desktop-large) {
    font-size: 26px;
    margin-bottom: 24px;
  }

  @include bp(desktop-wide) {
    font-size: 28px;
  }
}

.intro__location {
  margin-bottom: 16px;
  color: #ffffff;

  a {
    @include textIcon();
    @include textIconLocation();
  }

  span {
    @include textIcon();
    @include textIconPlay();
  }
}
